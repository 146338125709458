








































































































import { defineComponent } from "@vue/composition-api";
import { mapStores } from "pinia";
import { useGebruikerStore } from "@/pinia/gebruiker";

type Data = {
	loading: boolean;
};

export default defineComponent({
	name: "PfgGebruikerAbonnementsVorm",
	data(): Data {
		return {
			loading: false,
		};
	},
	computed: {
		...mapStores(useGebruikerStore),
	},
	async created() {
		await this.check();
	},
	methods: {
		async check() {
			this.loading = true;

			const { id } = this.$route.params;

			const numberId = Number(id);

			if (isNaN(numberId)) return;

			const currentId = this.gebruikerStore.id;

			if (currentId !== numberId) this.gebruikerStore.set(numberId);

			await this.getRequiredInfo();

			this.loading = false;
		},
		async getRequiredInfo() {
			await this.gebruikerStore.getSubscription();
		},
	},
});
