var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pfg-content pfg-content--full"},[_c('div',{staticClass:"pfg-content_container"},[_c('div',{staticClass:"pfg-content_1-1"},[_c('header',{staticClass:"pfg-header"},[_c('div',{staticClass:"pfg-header_top"},[_c('div',{staticClass:"pfg-header_top_left"},[_c('div',{staticClass:"a_flex a_align-items_center"},[_c('h1',{staticClass:"a_margin-bottom-0"},[_vm._v("Gegevens")]),_c('pfg-loader',{attrs:{"loading":_vm.loading}})],1)]),_c('div',{staticClass:"pfg-header_top_right"},[_c('router-link',{staticClass:"button v_ghost_brand_primary v_has-icon-right v_smaller a_margin-left-10",attrs:{"to":{
								name: 'Member appointment create',
								params: { id: _vm.gebruikerStore.id },
							}}},[_c('span',{staticClass:"button-txt"},[_vm._v("Afspraak inplannen")])])],1)])]),_c('div',{staticClass:"pfg-content_tab-bar"},[_c('router-link',{staticClass:"pfg-content_tab-bar_link",attrs:{"to":{
						name: 'Gebruiker persoonlijk gegevens',
						params: { id: _vm.gebruikerStore.id },
					}}},[_vm._v("Persoonlijke gegevens")]),_c('router-link',{staticClass:"pfg-content_tab-bar_link",attrs:{"to":{
						name: 'Gebruiker persoonlijk medisch',
						params: { id: _vm.gebruikerStore.id },
					}}},[_vm._v("Medische gegevens")]),_c('router-link',{staticClass:"pfg-content_tab-bar_link",attrs:{"to":{
						name: 'Gebruiker persoonlijk abonnementsvorm',
						params: { id: _vm.gebruikerStore.id },
					}}},[_vm._v("Abonnementsvorm")])],1),(_vm.gebruikerStore.subscription)?_c('div',{staticClass:"a_padding-40 a_bg_brand_white a_default-border-radius"},[(_vm.gebruikerStore.subscription.abonnementsvorm)?_c('div',{staticClass:"pfg-content_input"},[_vm._m(0),_c('div',{staticClass:"pfg-content_input_right"},[_vm._v(" "+_vm._s(_vm.gebruikerStore.subscription.abonnementsvorm)+" ")])]):_vm._e(),(_vm.gebruikerStore.subscription.arrangement)?_c('div',{staticClass:"pfg-content_input"},[_vm._m(1),_c('div',{staticClass:"pfg-content_input_right"},[_vm._v(" "+_vm._s(_vm.gebruikerStore.subscription.arrangement)+" ")])]):_vm._e(),(_vm.gebruikerStore.subscription.arrangementOver)?_c('div',{staticClass:"pfg-content_input"},[_vm._m(2),_c('div',{staticClass:"pfg-content_input_right"},[_vm._v(" "+_vm._s(_vm.gebruikerStore.subscription.arrangementOver)+" ")])]):_vm._e(),(_vm.gebruikerStore.subscription.lidsinds)?_c('div',{staticClass:"pfg-content_input"},[_vm._m(3),_c('div',{staticClass:"pfg-content_input_right"},[_vm._v(" "+_vm._s(_vm.gebruikerStore.subscription.lidsinds)+" ")])]):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading && !_vm.gebruikerStore.subscription),expression:"!loading && !gebruikerStore.subscription"}]},[_vm._v("Er zijn geen gegevens gevonden")])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pfg-content_input_left"},[_c('label',{staticClass:"pfg-content_input_label",attrs:{"for":"inputAbonnentsvorm"}},[_vm._v("Abonnementsvorm:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pfg-content_input_left"},[_c('label',{staticClass:"pfg-content_input_label",attrs:{"for":"inputAbonnentsvorm"}},[_vm._v("Arrangement:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pfg-content_input_left"},[_c('label',{staticClass:"pfg-content_input_label",attrs:{"for":"inputAbonnentsvorm"}},[_vm._v("Arrangement afgelopen:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pfg-content_input_left"},[_c('label',{staticClass:"pfg-content_input_label",attrs:{"for":"inputLidSinds"}},[_vm._v("Lid sinds:")])])}]

export { render, staticRenderFns }